import React from "react"
// import { graphql, useStaticQuery } from "gatsby"    
import { TransitionLink } from "@atoms/Link"
// import { globalHistory } from '@reach/router'
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
// import LinkList from '@projects/LinkList'
// import { menuData } from '@utility/UrlData'
// import { Svg } from '@atoms/Svg'

import * as css from "@css/layouts/l-section.module.styl"

export const sectionCss = css

export function Section({children, id, isMargin = true})
{
    return(
        <section className={isMargin ? css.lSection : css.lSectionNomargin } id={id}>
            <div className={css.lSection__inner}>
                {children}
            </div>
        </section>
    )
}

export function SectionHeader({ title, text, isBorder = true }) {
    return (
        <header className={isBorder ? css.lSection__header : css.lSection__headerNoborder}>
            <h2 className={css.aTitle}>{title}</h2>
            <div className={css.aDetail}>
                <p className={css.aLabel}>
                    YU SHIROTA OFFICIAL FANCLUB 
                    <br/>
                    LOVE & PEACE
                </p>
                <p className={css.aText}>{text}</p>
            </div>
        </header>
    )
}

export function SectionContent({ children, isBorder = true }) {
    return (
        <div className={isBorder ? css.lSection__content : css.lSection__contentNoBorder}>
            {children}
        </div>
    )
}

export function SectionIframe({ children, border }) {
    return (
        <div className={`${border ? css.lSection__iframeBorder : css.lSection__iframe}`}>
            {children}
        </div>
    )
}

export function SectionColumn({ col = 2, children, isBorder=false }) {
    return (
        <div className={isBorder ? css.lSection__column2Border : css.lSection__column2}>
            {children}
        </div>
    )
}

export function SectionFigureBox({ children, ...props }) {
    return (
        <div className={css.lSection__figureBox} {...props}>
            {children}
        </div>
    )
}


export function SectionMoreLink({ title, text, to, isBorder = true, onClick}) {
    if( onClick ){
        return (
            <div className={isBorder ? css.lSection__more : css.lSection__moreNoborder}>
                <div className={css.aLink} onClick={onClick}>
                    <h5 className={css.aTitle}>{title}</h5>
                    <span className={css.aArrow1} />
                    <p className={css.aText}>{text}</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className={isBorder ? css.lSection__more : css.lSection__moreNoborder}>
                <TransitionLink className={css.aLink} to={to} onClick={onClick}>
                    <h5 className={css.aTitle}>{title}</h5>
                    <span className={css.aArrow1}/>
                    <p className={css.aText}>{text}</p>
                </TransitionLink>
            </div>
        )
    }
}


