import * as React from "react"

import { globalHistory } from '@reach/router'
// import { Image } from "@components/atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import Seo from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { Section, SectionHeader, SectionContent, SectionMoreLink} from "@layouts/Section"
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"
// import * as css from "@css/layouts/l-error.module.styl"

function Error404Page(){
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    const { location } = globalHistory

    // if (typeof window !== 'undefined' && !location.pathname.match(/404/g)) {
    //     window.location = '/404.html';
    // }

    return(
        <>
            <Seo subtitle="404 Not Found." />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="404 Error"
                    text="エラ-"
                />
                <SectionContent isBorder={true}>
                    <section>
                        <p>
                            指定されたページは存在しません。原因は以下のようなものが考えられます。
                        </p>
                        <ul>
                            <li>URLの有効期限が切れている</li>
                            <li>URLが間違っている</li>
                            <li>該当ページのURLが変更された</li>
                            <li>サーバーとの通信で問題が発生した</li>
                        </ul>
                    </section>
                </SectionContent>
                <SectionMoreLink
                    title="Top"
                    text="トップへ"
                    to={userLogin ? "/member/" : "/"}
                />
            </Section>
        </>
    )
}

export default Error404Page
