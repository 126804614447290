// extracted by mini-css-extract-plugin
export var aArrow1 = "Z";
export var aArrow2 = "_";
export var aButton1 = "P";
export var aButton2 = "Q";
export var aButton3 = "R";
export var aButton4 = "S";
export var aButton5 = "T";
export var aButtonHeaderLogin = "U";
export var aButtonHeaderRegister = "V";
export var aButtonHeaderSpLogin = "Y";
export var aButtonLogin = "X";
export var aButtonRegister = "W";
export var aButtons = "ja";
export var aCategory = "N";
export var aCategoryGray = "M";
export var aDate = "O";
export var aDetail = "H";
export var aFullImage = "ia";
export var aLabel = "I";
export var aLink = "K";
export var aTags = "ka";
export var aText = "ca";
export var aTitle = "F";
export var aTitle1 = "ha";
export var animationMarquee = "oa";
export var lSection = "aa";
export var lSection__column2 = "ea";
export var lSection__column2Border = "da";
export var lSection__content = "fa";
export var lSection__contentNoBorder = "ga";
export var lSection__figureBox = "na";
export var lSection__header = "G";
export var lSection__headerNoborder = "E";
export var lSection__iframe = "ma";
export var lSection__iframeBorder = "la";
export var lSection__inner = "ba";
export var lSection__more = "L";
export var lSection__moreNoborder = "J";